import Menu from "./components/menu";
import Speakers from "./components/speakers";
import Swipers from "./components/swipers";
import Counter from "./components/counter";
import OnScrollAnimation from "./components/on-scroll-animation";

document.addEventListener("DOMContentLoaded", function () {
  const menu = new Menu();
  new Speakers(menu);
  new Swipers();
  new Counter();
  new OnScrollAnimation();
});
