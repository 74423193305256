import gsap from "gsap";
import Swiper, { Navigation, EffectFade } from "swiper";

class Speakers {
  constructor(menu) {
    this.speakers = document.getElementById("js-speakers");
    this.speakersPopup = document.getElementById("js-speakers-popup");
    this.speakerCards = document.querySelectorAll(".js-speaker-card");
    this.speakersSwiperCount = document.getElementById(
      "js-speakers-swiper-count"
    );
    this.menu = menu;
    this.togglePopup = this.togglePopup.bind(this);
    menu.registerClosePopupCallback(this.togglePopup);
    if (!this.speakers) return;
    this.mobileBreakpoint = 992;
    this.prevScreenWidth = window.innerWidth;
    this.cardOpenClass = "js-open";
    this.mobilePopupOpenClass = "js-popup-open";
    this.popupState = "closed";
    this.isAnimating = false;
    this.speakerCards.forEach((card, i) => {
      card.addEventListener("click", (e) => {
        if (e.target.classList.contains("arrow-link")) {
          const wWidth= window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
         if( wWidth < this.mobileBreakpoint){
            e.preventDefault();
            this.handleSpeakerClick(card, i);
         }
        } else if (
          e.target.classList.contains("speaker-card__back__close") ||
          e.target.classList.contains("speaker-card__back__close__bar")
        ) {
          card.classList.remove(this.cardOpenClass);
        }
      });
    });
    window.addEventListener("resize", () => {
      this.windowResizeHandler();
    });
    this.swiper = null;
    this.initSwiper();
  }

  initSwiper() {
    this.swiper = new Swiper(".speakers__popup .swiper", {
      speed: 500,
      slidesPerView: 1,
      autoHeight: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      modules: [Navigation, EffectFade],
      navigation: {
        nextEl: ".speakers__popup .swiper-next",
        prevEl: ".speakers__popup .swiper-prev",
      },
      on: {
        slideChange: (swiper) => {
          this.speakersSwiperCount.innerText = `${swiper.realIndex + 1}/${
            swiper.slides.length
          }`;
        },
      },
    });
  }

  removeCardsOpenClass() {
    this.speakerCards.forEach((card) => {
      card.classList.remove(this.cardOpenClass);
    });
  }

  windowResizeHandler() {
    if (
      window.innerWidth >= this.mobileBreakpoint &&
      this.prevScreenWidth < this.mobileBreakpoint
    ) {
      this.removeCardsOpenClass();
      if (this.popupState == "opened") {
        this.togglePopup();
      }
    } else if (
      window.innerWidth < this.mobileBreakpoint &&
      this.prevScreenWidth >= this.mobileBreakpoint
    ) {
      this.removeCardsOpenClass();
    }
    this.prevScreenWidth = window.innerWidth;
  }

  handleSpeakerClick(card, i) {
    card.classList.add(this.cardOpenClass);
    if (window.innerWidth < this.mobileBreakpoint) {
      this.togglePopup();
      this.swiper.slideTo(i);
      this.speakersSwiperCount.innerText = `${i + 1}/${
        this.speakerCards.length
      }`;
      this.swiper.update();
      this.swiper.updateAutoHeight();
    }
  }

  togglePopup() {
    if (this.isAnimating) return;
    this.isAnimating = true;
    if (this.popupState == "closed") {
      this.popupState = "opened";
      this.speakers.classList.add(this.mobilePopupOpenClass);
      this.menu.togglePopup();
      gsap.to(this.speakersPopup, {
        autoAlpha: 1,
        display: "block",
        duration: 0.5,
      });
      const that = this;
      setTimeout(() => {
        that.isAnimating = false;
      }, 500);
    } else {
      this.popupState = "closed";
      this.speakers.classList.remove(this.mobilePopupOpenClass);
      this.menu.togglePopup();
      gsap.to(this.speakersPopup, {
        autoAlpha: 0,
        display: "none",
        duration: 0.5,
      });
      const that = this;
      setTimeout(() => {
        that.isAnimating = false;
      }, 500);
    }
  }
}

export default Speakers;
