import Swiper, { Navigation } from "swiper";

class Swipers {
  constructor() {
    this.homeSpeakersSwiperEl = document.querySelector(
      ".home-speakers-outer .swiper"
    );
    if (this.homeSpeakersSwiperEl) {
      new Swiper(this.homeSpeakersSwiperEl, {
        speed: 500,
        slidesPerView: 1,
        spaceBetween: 50,
        modules: [Navigation],
        navigation: {
          nextEl: ".home-speakers-outer .swiper-next",
          prevEl: ".home-speakers-outer .swiper-prev",
        },
        breakpoints: {
          576: {
            slidesPerView: 2,
          },
          992: {
            slidesPerView: 3,
          },
        },
      });
      this.updateSpeakersSwiperBtnsTop();
      window.addEventListener("resize", () => {
        this.updateSpeakersSwiperBtnsTop();
      });
    }

    this.homeNewsSwiperEl = document.querySelector(".home-news .swiper");
    if (this.homeNewsSwiperEl) {
      new Swiper(this.homeNewsSwiperEl, {
        speed: 500,
        slidesPerView: 1,
        spaceBetween: 50,
        modules: [Navigation],
        navigation: {
          nextEl: ".home-news .swiper-next",
          prevEl: ".home-news .swiper-prev",
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
          },
          1200: {
            slidesPerView: 3,
          },
        },
      });
      this.updateNewsSwiperBtnsTop();
      window.addEventListener("resize", () => {
        this.updateNewsSwiperBtnsTop();
      });
    }
  }

  updateSpeakersSwiperBtnsTop() {
    const btnNext = document.querySelector(".home-speakers-outer .swiper-next");
    const btnPrev = document.querySelector(".home-speakers-outer .swiper-prev");
    const firstImg = document.querySelector(
      ".home-speakers-outer .swiper-slide .avatar"
    );
    const top = firstImg.offsetHeight / 2;
    btnNext.style.top = top + 50 + "px";
    btnPrev.style.top = top + 50 + "px";
  }

  updateNewsSwiperBtnsTop() {
    const btnNext = document.querySelector(".home-news .swiper-next");
    const btnPrev = document.querySelector(".home-news .swiper-prev");
    const firstImg = document.querySelector(
      ".home-news .swiper-slide .news-card__img"
    );
    const top = firstImg.offsetHeight / 2;
    btnNext.style.top = top + "px";
    btnPrev.style.top = top + "px";
  }
}

export default Swipers;
