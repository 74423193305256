import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class Counter {
  constructor() {
    this.counterWrappers = document.querySelectorAll(".js-counter-wrapper");
    if (!this.counterWrappers.length) return;
    gsap.registerPlugin(ScrollTrigger);
    for (let i = 0; i < this.counterWrappers.length; i++) {
      const counters = this.counterWrappers[i].querySelectorAll(".js-counter");
      for (let j = 0; j < counters.length; j++) {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: counters[j],
            start: "top 95%",
          },
        });
        tl.from(counters[j], {
          textContent: 0,
          duration: 3,
          ease: "Power1.easeInOut",
          snap: { textContent: 1 },
        });
      }
    }
  }
}

export default Counter;
