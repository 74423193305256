import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

class OnScrollAnimation {
  constructor() {
    const elementsToAnimate = document.querySelectorAll(".animate-on-scroll");
    gsap.registerPlugin(ScrollTrigger);
    elementsToAnimate.forEach((element) => {
      const animationType =
        element.getAttribute("data-animation") || "fadeInUp";
      const duration = parseFloat(element.getAttribute("data-duration")) || 0.5;
      const delay = parseFloat(element.getAttribute("data-delay")) || 0;

      let options = {
        opacity: 0,
        y: 50,
      };

      if (animationType == "fadeInDown") {
        options = {
          opacity: 0,
          y: -50,
        };
      } else if (animationType == "zoomIn") {
        options = {
          opacity: 0,
          scale: 0.3,
        };
      } else if (animationType == "fadeInLeft") {
        options = {
          opacity: 0,
          x: -50,
        };
      } else if (animationType == "fadeInRight") {
        options = {
          opacity: 0,
          x: 50,
        };
      }

      element.classList.add("animated");
      gsap.from(element, {
        ...options,
        duration: duration,
        delay: delay,
        ease: "Power1.easeInOut",
        scrollTrigger: {
          trigger: element,
          start: "top bottom",
        },
      });
    });
  }
}

export default OnScrollAnimation;
