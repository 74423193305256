import gsap from "gsap";

class Menu {
  constructor() {
    this.menu = document.getElementById("js-menu");
    this.pageContent = document.getElementById("js-page-content");
    this.menuToggler = document.getElementById("js-menu-toggler");
    this.menuMob = document.getElementById("js-menu-mob");
    this.menuState = "closed";
    this.menuOpenClass = "js-menu-open";
    this.popupOpenClass = "js-popup-open";
    this.animationEnableClass = "js-enable-animation";
    this.animationStartClass = "js-animation-start";
    this.isAnimating = false;
    this.menuToggler.addEventListener("click", () => {
      this.menuTogglerHandler();
    });
    this.updatePageContentPadding();
    this.prevScreenWidth = window.innerWidth;
    this.mobileBreakpoint = 1200;
    this.isPopup = false;
    this.closePopupCallback = null;
    window.addEventListener("resize", () => {
      this.updatePageContentPadding();
      if (
        window.innerWidth >= this.mobileBreakpoint &&
        this.prevScreenWidth < this.mobileBreakpoint &&
        this.menuState == "opened"
      ) {
        this.isPopup = false;
        this.toggleMenu();
      }
      this.prevScreenWidth = window.innerWidth;
    });
  }

  menuTogglerHandler() {
    if (
      this.isPopup &&
      this.menuState == "opened" &&
      this.closePopupCallback != null
    ) {
      this.closePopupCallback();
    } else {
      this.toggleMenu();
    }
  }

  toggleMenu() {
    if (this.isAnimating) return;
    this.isAnimating = true;
    if (this.menuState == "closed") {
      this.menuState = "opened";
      this.updateBodyOverflow();
      this.menu.classList.add(this.menuOpenClass);
      if (this.isPopup) this.menu.classList.add(this.popupOpenClass);
      else
        gsap.to(this.menuMob, {
          autoAlpha: 1,
          display: "block",
          duration: 0.5,
        });
      gsap.to(this.menuToggler.children[0], { y: "100%", duration: 0.25 });
      gsap.to(this.menuToggler.children[0], {
        rotate: "45deg",
        delay: 0.25,
        duration: 0.25,
      });
      gsap.to(this.menuToggler.children[1], { scaleX: 0, scaleY: 0 });
      gsap.to(this.menuToggler.children[2], { y: "-100%", duration: 0.25 });
      gsap.to(this.menuToggler.children[2], {
        rotate: "-45deg",
        delay: 0.25,
        duration: 0.25,
      });
      const that = this;
      setTimeout(() => {
        that.isAnimating = false;
      }, 500);
    } else {
      this.menuState = "closed";
      this.menu.classList.remove(this.menuOpenClass);
      if (this.isPopup) this.menu.classList.remove(this.popupOpenClass);
      else
        gsap.to(this.menuMob, { autoAlpha: 0, display: "none", duration: 0.5 });
      this.updateBodyOverflow();
      gsap.to(this.menuToggler.children[0], { rotate: 0, duration: 0.25 });
      gsap.to(this.menuToggler.children[0], {
        y: 0,
        delay: 0.25,
        duration: 0.25,
      });
      gsap.to(this.menuToggler.children[1], { scaleX: 1, scaleY: 1 });
      gsap.to(this.menuToggler.children[2], { rotate: 0, duration: 0.25 });
      gsap.to(this.menuToggler.children[2], {
        y: 0,
        delay: 0.25,
        duration: 0.25,
      });
      const that = this;
      setTimeout(() => {
        that.isAnimating = false;
      }, 500);
      this.isPopup = false;
    }
  }

  updateBodyOverflow() {
    if (document.body.scrollHeight > window.innerHeight) {
      if (this.menuState == "opened") {
        document.body.classList.add("overflow-hidden");
        const scrollDiv = document.createElement("div");
        scrollDiv.className = "scrollbar-measure";
        document.body.appendChild(scrollDiv);
        const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        document.body.removeChild(scrollDiv);
        document.body.style.paddingRight = scrollbarWidth + "px";
        this.menu.style.paddingRight = scrollbarWidth + "px";
      } else {
        document.body.classList.remove("overflow-hidden");
        this.menu.style.paddingRight = "0";
        document.body.style.paddingRight = "0";
      }
    }
  }

  updatePageContentPadding() {
    this.pageContent.style.paddingTop = `${this.menu.offsetHeight}px`;
  }

  togglePopup() {
    this.isPopup = true;
    this.toggleMenu();
  }

  registerClosePopupCallback(callback) {
    this.closePopupCallback = callback;
  }
}

export default Menu;
